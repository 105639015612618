import "./App.css";
import { useEffect, useState, lazy, useTransition } from "react";
import { CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import CookieBanner from "./components/CookieBanner";
import { scroller } from "react-scroll";
import TagManager from "react-gtm-module";
import { SseProvider } from "./context/SseContext";
import { CoursesProvider } from "./context/CoursesContext";
import {
  trackInitialize,
  usePageTracking,
  useScreenviewTracking,
} from "./_helpers/trackingAnalytics";
import Platform from "./Displays/Platform";
import Public from "./Displays/Public";
import { SnackbarProvider } from "./context/SnackbarContext";

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const Error404 = lazyWithRetry(() => import("./Error404/Error404"));
const Main = lazyWithRetry(() => import("./Main/Main"));
const TrackRecord = lazyWithRetry(() => import("./TrackRecord/TrackRecord"));
const Partners = lazyWithRetry(() => import("./Partners/Partners"));
const Account = lazyWithRetry(() => import("./Account/Account"));
const Dashboard = lazyWithRetry(() => import("./Dashboard/Dashboard"));
const Courses = lazyWithRetry(() => import("./Courses/Courses"));
const Library = lazyWithRetry(() => import("./Library/Library"));
const Trade = lazyWithRetry(() => import("./Trade/Trade"));
const Analytics = lazyWithRetry(() => import("./Analytics/Analytics"));
const FAQ = lazyWithRetry(() => import("./FAQ/FAQ"));
const Register = lazyWithRetry(() => import("./User/Register/Register"));
const Login = lazyWithRetry(() => import("./User/Login/Login"));
const Forgot = lazyWithRetry(() => import("./User/Forgot/Forgot"));
const Reset = lazyWithRetry(() => import("./User/Forgot/Reset"));
const TermsAndConditions = lazyWithRetry(() =>
  import("./Legal/TermsAndConditions")
);
const PrivacyPolicy = lazyWithRetry(() => import("./Legal/PrivacyPolicy"));
const CookiePolicy = lazyWithRetry(() => import("./Legal/CookiePolicy"));

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#F5B129",
      },
      secondary: {
        main: "#DB545A",
      },
      black: createColor("#010003"),
      light: createColor("#EEEEEE"),
      dark: createColor("#0D0B1E"),
      yellow: createColor("#F5B129"),
      lightYellow: createColor("#f9dea5"),
      lighterYellow: createColor("#fffdd3"),
      red: createColor("#DB545A"),
      redHover: createColor("#A34044"),
      green: createColor("#228B22"),
      greenHover: createColor("#236E23"),
      gray: createColor("#555555"),
      lightGray: createColor("#AAAAAA"),
      white: createColor("#FFFFFF"),
      grayHover: createColor("#E9D9DAB3"),
      tradeDark: createColor("#0F111A"),
      tradeGray: createColor("#1E2234"),
      orange: createColor("#FD9847"),
    },
    typography: {
      fontFamily:
        "'Metropolis', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
      h1: {
        fontWeight: 700,
        fontSize: "3rem",
      },
      h2: {
        fontWeight: 700,
        fontSize: "2.5rem",
      },
      h3: {
        fontWeight: 700,
        fontSize: "1.8rem",
      },
      h4: {
        fontWeight: 600,
        fontSize: "1.6rem",
      },
      h5: {
        fontWeight: 600,
        fontSize: "1.4rem",
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 300,
      },
      subtitle2: {
        fontWeight: 300,
        fontSize: "0.8rem",
        color: "#555555",
      },
      body1: {
        fontSize: "1rem",
      },
    },
  })
);

function App() {
  // Google Tag Manager and Facebook Pixel
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NS9SKM5" });
    trackInitialize();
  }, []);

  // START - Google Analytics Setup
  usePageTracking();
  useScreenviewTracking();
  // END - Google Analytics Setup

  // https://github.com/remix-run/react-router/issues/8860#issuecomment-1249914724
  function useConcurrentTransition() {
    const location = useLocation();
    const [oldLocation, setOldLocation] = useState(location);
    const [, startTransition] = useTransition();

    useEffect(() => {
      // if the path or search params change, mark this is a navigational transition
      setOldLocation((oldLocation) =>
        oldLocation.pathname !== location.pathname ||
        oldLocation.search !== location.search ||
        oldLocation.hash !== location.hash
          ? location
          : oldLocation
      );
    }, [location]);

    useEffect(() => {
      // tell concurrent mode to pause UI rendering for a moment
      startTransition(() => {});
    }, [oldLocation]);

    useEffect(() => {
      if (oldLocation.key === location.key) {
        if (location.hash) {
          scroller.scrollTo(location.hash.substring(1), {
            offset: -48,
          });
        }
      }
    }, [location, oldLocation]);

    return oldLocation;
  }
  const location = useConcurrentTransition();
  // https://github.com/remix-run/react-router/issues/8860#issuecomment-1249914724

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieBanner />
      <UserProvider>
        <SseProvider>
          <CoursesProvider>
            {/* <AssessmentProvider> */}
            <SnackbarProvider>
              <Routes location={location}>
                <Route path="/" element={<Public component={<Main />} />} />
                <Route path="/trackrecord" element={<Public component={<TrackRecord />} />} />
                <Route
                  path="/partners"
                  element={<Public component={<Partners />} />}
                />

                <Route
                  path="/dashboard"
                  element={<Platform component={<Dashboard />} />}
                />
                <Route
                  path="/courses"
                  element={<Platform component={<Courses />} />}
                />
                <Route
                  path="/library"
                  element={<Platform component={<Library />} />}
                />
                <Route
                  path="/trade"
                  element={<Platform component={<Trade />} fullWidth />}
                />
                <Route
                  path="/analytics"
                  element={<Platform component={<Analytics />} />}
                />
                <Route
                  path="/faq"
                  element={<Platform component={<FAQ />} emptySpace />}
                />
                <Route
                  path="/account"
                  element={<Platform component={<Account />} />}
                />
                <Route
                  path="/register"
                  element={<Public component={<Register />} />}
                />
                <Route
                  path="/login"
                  element={<Public component={<Login />} />}
                />
                <Route
                  path="/forgot"
                  element={<Public component={<Forgot />} />}
                />
                <Route
                  path="/reset"
                  element={<Public component={<Reset />} />}
                />
                <Route
                  path="/termsandconditions"
                  element={<Public component={<TermsAndConditions />} />}
                />
                <Route
                  path="/privacypolicy"
                  element={<Public component={<PrivacyPolicy />} />}
                />
                <Route
                  path="/cookiepolicy"
                  element={<Public component={<CookiePolicy />} />}
                />

                <Route path="*" element={<Public component={<Error404 />} />} />
              </Routes>
            </SnackbarProvider>
            {/* </AssessmentProvider> */}
          </CoursesProvider>
        </SseProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
